<template lang="">
  <BaseLayout mainClass="bg-oWhite">
    <AddEditMarketplaceBillingInfo @refresh="fetchData()" />
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :z-index="10"
      :opacity="0.25"
    />
    <section
      class="px-5 md:px-16 lg:px-32 xl:px-40 2xl:px-40 bg-gray-100 grid grid-cols-12 py-12  gap-4"
    >
      <div class="col-span-12 md:col-span-7">
        <div>
          <!-- Cart-start -->
          <div>
            <div v-if="haveAnyUnpaidOrderCreated">
              <template v-for="(item, index) in orderDetails">
                <div
                  :key="`order-${index}`"
                  class="flex border border-gray-200 rounded-md p-2 gap-2"
                >
                  <div class="w-1/6 ">
                    <img
                      class="w-auto h-auto rounded-md"
                      :src="preorderDetails.vehicle_model.vehicle_image"
                      alt="vehicle"
                    />
                  </div>
                  <div class="w-5/6">
                    <div class="text-lg ">
                      <div class="flex justify-between">
                        <h3 class="text-gray-700">
                          {{ preorderDetails.vehicle_model.vehicle_model_name }}
                        </h3>
                        <h3 class="text-gray-900 font-bold">
                          {{ getCurrencySymbol }}
                          {{ preorderDetails.per_unit_price * orderCount }}
                        </h3>
                      </div>
                      <div class="flex justify-between items-center">
                        <div class="text-sm text-gray-400">
                          <p>
                            Per vehicle: {{ getCurrencySymbol
                            }}{{ preorderDetails.per_unit_price }}
                          </p>
                          <p>
                            Country:
                            {{ preorderDetails.lock_manufacturer.country.name }}
                          </p>
                        </div>
                        <div>
                          <CounterButton
                            class="my-2"
                            :maxRange="getLeftVehicles(preorderDetails)"
                            :defaultCounterValue="
                              item.number_of_vehicles_to_buy
                            "
                            @input="
                              ($event) => {
                                orderCount = $event
                              }
                            "
                            @leave-update="updateOrder()"
                          />
                        </div>
                      </div>
                      <div>
                        <p class="font-bold text-sm">
                          Sales end:
                          <span class="my-style">{{
                            getFormattedDateTime(
                              preorderDetails.order_end_date,
                              'D MMM, YYYY'
                            )
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <p v-else>Please Create an order first</p>
          </div>

          <!-- Cart-end -->

          <!-- payment-add-start -->
          <h2 class="font-bold text-2xl mt-10 mb-5">Payment Information</h2>
          <div class="border border-gray-200 rounded-md p-2">
            <div v-if="haveAnyPaymethodCreated" class="flex justify-between">
              <div class="w-11/12">
                <template v-for="(item, index) in paymentMethodsDetails">
                  <div :key="`paymentMethod-${index}`">
                    <div class="flex  gap-2">
                      <div>
                        <i
                          :class="getPaymentCardIconClass(item)"
                          class="text-3xl"
                        />
                      </div>
                      <div class="font-bold ">
                        <p class="text-base">
                          {{ `Ending with ${item.detail}` }}
                        </p>
                        <p class="text-sm text-gray-500">
                          Expired
                          {{ getFormattedDateTime(item.expiry, 'MM/YY') }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="">
                <span
                  class="text-gray-600 cursor-pointer"
                  @click="deletePaymentMethod()"
                  ><u>Delete</u></span
                >
              </div>
            </div>
            <div v-else>
              <button
                class="relative bg-gray-300 rounded-md w-48 h-12 mt-5 font-bold hover:bg-gray-400"
                @click="addPaymentCard()"
                style="
    background-color: var(--brand-primary-color);
    color: white;

  "
              >
                <loading
                  :height="25"
                  color="#000000"
                  :active="isAddCardLoading"
                  loader="bars"
                  :class="`outline-none`"
                  :is-full-page="false"
                />
                Add New Card
              </button>
            </div>
            <iframe ref="iframeRef" class="hidden" />
          </div>
          <!-- payment-add-end -->
          <!-- billing-start -->
          <h2 class="font-bold text-2xl mt-10 mb-5">Billing Information</h2>
          <div
            class="flex justify-between border border-gray-200 rounded-md p-2"
          >
            <div class="w-11/12">
              <div v-if="haveAnyBillingAddressCreated">
                <template v-for="(item, index) in buyerBillingInfoDetails">
                  <div :key="`billinginfo-${index}`">
                    <p>
                      Name:
                      {{
                        item.billing_category === 'company'
                          ? item.company_name
                          : item.legal_name
                      }}
                    </p>
                    <p>
                      Email:{{
                        item.billing_category === 'company'
                          ? item.company_email
                          : item.email
                      }}
                    </p>
                    <p v-if="item.city">City:{{ item.city }}</p>
                    <p v-if="item.zip_code">Zip Code:{{ item.zip_code }}</p>
                    <p v-if="item.country">Country:{{ item.country.name }}</p>
                    <p v-if="item.state">State:{{ item.state }}</p>
                    <p v-if="item.vat_id_number">
                      VAT Id:{{ item.vat_id_number }}
                    </p>

                    <p v-if="item.address">Address:{{ item.address }}</p>
                  </div>
                </template>
              </div>
              <p v-else class="text-gray-500">
                Please add billing information before Pay
              </p>
            </div>
            <div class="">
              <span
                class="text-gray-600 cursor-pointer"
                @click="
                  haveAnyBillingAddressCreated
                    ? editBillingInfo()
                    : addBillingInfo()
                "
                ><u>{{
                  haveAnyBillingAddressCreated ? 'Edit' : 'Add'
                }}</u></span
              >
            </div>
          </div>
          <!-- billing-end -->
        </div>
      </div>
      <!-- pay-start -->
      <div class="col-span-12 md:col-span-5">
        <div class="border border-gray-200 rounded-md p-2">
          <div class="flex justify-between font-bold ">
            <p>
              Subtotal
              <span class="text-gray-500">({{ orderCount }} item)</span>
            </p>
            <p class="text-gray-500">
              {{ getCurrencySymbol }} {{ getSubtotalOrderAmount }}
            </p>
          </div>
          <div class="flex justify-between font-bold my-3">
            <p>
              Discount
            </p>
            <p class="text-gray-500">{{ getCurrencySymbol }} 0</p>
          </div>
          <div class="flex justify-between font-bold my-3">
            <p>
              VAT
            </p>
            <p class="text-gray-500">{{ getCurrencySymbol }} 0</p>
          </div>
          <hr class="bg-gray-400 h-0.5" />
          <div class="flex justify-between font-bold my-3 text-xl">
            <p>
              Total
            </p>
            <p>{{ getCurrencySymbol }} {{ getSubtotalOrderAmount }}</p>
          </div>
          <div class="mt-10">
            <button
              @click="paymentCheckout()"
              class="relative w-full border-2 py-3 rounded-full text-lg font-bold"
              :class="
                isDisablePayNow
                  ? 'bg-gray-900 border-gray-700 hover:bg-gray-800 text-oWhite'
                  : 'bg-gray-300 border-gray-200 hover:bg-gray-300 text-gray-600 hover:text-gray-800'
              "
              style="
    background-color: var(--brand-primary-color);
    color: white;
    transition: opacity 0.3s;
  "
            >
              <loading
                :height="25"
                :color="BRAND_CONFIG.COLOR.PRIMARY_COLOR"
                :active="isPaymentCheckoutLoading"
                loader="bars"
                :class="`outline-none rounded-full`"
                :is-full-page="false"
              />
              Pay Now
            </button>
          </div>
          <div class="flex items-center justify-center py-1 mt-2">
            <img
              src="@/assets/marketplace/secure-shield-green.svg"
              alt="secure-shield-green"
            />
            <span class="pl-3">Payment is completely secure.</span>
          </div>
        </div>
      </div>
      <!-- pay-end -->
    </section>
  </BaseLayout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import CounterButton from '@/composites/marketplace-buyer/CounterButton.vue'
import { useEndpoints } from '@/composables'
import { getFormattedDateTime } from '@/utils/datetime'
import { EventBus } from '@/utils'
import AddEditMarketplaceBillingInfo from '@/views/marketplace-buyer/AddEditMarketplaceBillingInfo.vue'
import { MarketplaceBuyerBillingInfoConfig } from '@/config/MarketplaceBuyerBillingInfoConfig'
import { TYPE, POSITION } from 'vue-toastification'
import { BRAND_CONFIG } from '@/config/brand.config'
export default {
  name: 'MarketplacePreOrderPayment',
  components: {
    BaseLayout,
    CounterButton,
    AddEditMarketplaceBillingInfo,
  },
  data: () => ({
    isLoading: false,
    isAddCardLoading: false,
    isPaymentCheckoutLoading: false,
    orderDetails: [],
    preorderDetails: {},
    buyerBillingInfoDetails: [],
    paymentMethodsDetails: [],
    countries: [],
    orderCount: 1,
    BRAND_CONFIG: BRAND_CONFIG,
  }),
  async created() {
    this.fetchData()
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getCurrencySymbol() {
      return this.preorderDetails.fleet.country.currency_symbol
    },
    getSubtotalOrderAmount() {
      return this.preorderDetails.per_unit_price * this.orderCount
    },
    getCurrentOrderCount() {
      return this.$store.getters['marketplacebuyer/getCurrentOrderCount']
    },
    haveAnyUnpaidOrderCreated() {
      return this.orderDetails.length > 0
    },
    haveAnyBillingAddressCreated() {
      return this.buyerBillingInfoDetails.length > 0
    },
    haveAnyPaymethodCreated() {
      return this.paymentMethodsDetails.length > 0
    },
    isDisablePayNow() {
      if (
        this.haveAnyUnpaidOrderCreated &&
        this.haveAnyBillingAddressCreated &&
        this.haveAnyPaymethodCreated
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    getFormattedDateTime,
    async fetchData() {
      this.isLoading = true
      const countriesReq = this.$http.get(
        useEndpoints.marketplaceBuyer.countries()
      )

      const orderDetailsReq = this.$http.get(
        useEndpoints.marketplaceBuyer.orderDetails()
      )

      const preorderDetailsReq = this.$http.get(
        useEndpoints.marketplaceBuyer.preorderDetails(this.id)
      )
      const buyerBillingInfoReq = this.$http.get(
        MarketplaceBuyerBillingInfoConfig.api.index()
      )

      const paymentMethodsReq = this.$http.get(
        useEndpoints.marketplaceBuyer.paymentMethods()
      )

      const requests = [
        countriesReq,
        orderDetailsReq,
        preorderDetailsReq,
        buyerBillingInfoReq,
        paymentMethodsReq,
      ]

      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            // let data = {}
            const [
              countriesRes,
              orderDetailsRes,
              preorderDetailsRes,
              buyerBillingInfoRes,
              paymentMethodsRes,
            ] = responses

            this.countries = countriesRes.data.data
            this.orderDetails = orderDetailsRes.data.data.filter(
              (item) => item.order_status === 'unpaid'
            )
            this.preorderDetails = preorderDetailsRes.data.data
            this.buyerBillingInfoDetails = buyerBillingInfoRes.data.data
            this.paymentMethodsDetails = paymentMethodsRes.data
            console.log('paymentMethodsRes', paymentMethodsRes)
          })
        )
        .catch((err) => {
          console.warn({ err })

          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Server Error`,
            text: 'Failed to retrieve data. Please try again.',
          })
        })
        .finally(() => (this.isLoading = false))
    },
    async updateOrder() {
      this.isLoading = true
      const formData = new FormData()
      formData.append('number_of_vehicles_to_buy', this.orderCount)
      await this.$http
        .patch(
          useEndpoints.marketplaceBuyer.updateOrderDetails(
            this.orderDetails[0].id
          ),
          formData
        )
        .then((res) => {
          console.log('updated-m', res)
          this.$toast(`Order quantity is updated`, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.SUCCESS,
          })
        })
        .catch((err) => {
          console.log('update-order-err', err)
          this.$toast(`${err}`, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.ERROR,
          })
        })
        .finally(() => (this.isLoading = false))
    },
    async deletePaymentMethod() {
      this.isLoading = true
      await this.$http
        .delete(
          useEndpoints.marketplaceBuyer.deletePaymentMethods(
            this.paymentMethodsDetails[0].id
          )
        )
        .then((res) => {
          console.log('deleted-success', res.data)
          this.fetchData()
          this.$toast(`Payment Method has been deleted`, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.SUCCESS,
          })
        })
        .catch((err) => {
          console.log('deleted-err', err)
          this.$toast(`${err}`, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.ERROR,
          })
        })
        .finally(() => (this.isLoading = false))
    },
    async getPreorderDetails() {
      this.isLoading = true
      await this.$http
        .get(useEndpoints.marketplaceBuyer.preorderDetails(this.id))
        .then((res) => {
          console.log('preorderDetails-p', res.data.data)
          this.preorderDetails = res.data.data
        })
        .finally(() => (this.isLoading = false))
    },
    async getBillingInfoDetails() {
      this.isLoading = true
      await this.$http
        .get(useEndpoints.marketplaceBuyer.preorderDetails(this.id))
        .then((res) => {
          console.log('preorderDetails-p', res.data.data)
          this.preorderDetails = res.data.data
        })
        .finally(() => (this.isLoading = false))
    },
    getLeftVehicles(data) {
      return data.total_vehicle_number - data.total_purchased_vehicle
    },
    addBillingInfo() {
      console.log('bill-a')
      EventBus.$emit(MarketplaceBuyerBillingInfoConfig.events.editingData, {
        countries: this.countries,
        buyerBillingInfoDetailsId: '',
      })
      dispatchEvent(
        new Event(MarketplaceBuyerBillingInfoConfig.events.sorToggle)
      )
    },
    editBillingInfo() {
      console.log('bill-e')
      EventBus.$emit(MarketplaceBuyerBillingInfoConfig.events.editingData, {
        countries: this.countries,
        buyerBillingInfoDetailsId: this.buyerBillingInfoDetails[0].id,
      })
      dispatchEvent(
        new Event(MarketplaceBuyerBillingInfoConfig.events.sorToggle)
      )
    },
    async addPaymentCard() {
      this.isAddCardLoading = true
      await this.$http
        .post(MarketplaceBuyerBillingInfoConfig.api.addPaymentMethod())
        .then((res) => {
          const ref = this.$refs.iframeRef
          ref.contentWindow.document.open()
          ref.contentWindow.document.write(res.data)
          this.isAddCardLoading = false
        })
        .finally(() => (this.isLoading = false))
    },
    getPaymentCardIconClass(card) {
      const brand = card?.brand?.toLowerCase() || ''
      // get font awesome icon by card type
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
    async paymentCheckout() {
      if (
        this.haveAnyUnpaidOrderCreated &&
        this.haveAnyBillingAddressCreated &&
        this.haveAnyPaymethodCreated
      ) {
        this.isPaymentCheckoutLoading = true

        const url = useEndpoints.marketplaceBuyer.paymentCheckout(
          this.paymentMethodsDetails[0].id
        )
        const data = new FormData()
        data.append('order_id', this.orderDetails[0].id)

        await this.$http
          .post(url, data)
          .then((res) => {
            console.log('paymentCheckout', res)
            this.$toast(res.data.data.message, {
              position: POSITION.BOTTOM_RIGHT,
              type: TYPE.SUCCESS,
            })
            setTimeout(() => {
              this.$router.push(`/`)
            }, 5000)

            localStorage.removeItem('route-path')
          })
          .catch((err) => {
            console.log('paymentCheckout-err', { err })
            this.$toast(err.message, {
              position: POSITION.BOTTOM_RIGHT,
              type: TYPE.ERROR,
            })
          })
          .finally(() => (this.isPaymentCheckoutLoading = false))
      } else {
        if (!this.haveAnyBillingAddressCreated) {
          this.$toast('Please,Add Billing information.', {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.WARNING,
          })
        }
        if (!this.haveAnyPaymethodCreated) {
          this.$toast('Please,Add Payment information.', {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.WARNING,
          })
        }
      }
    },
  },
}
</script>
<style lang="scss">
button:hover {
  opacity: 0.75;
}
.my-style {
  color: var(--brand-primary-color);
}
</style>
