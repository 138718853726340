<template>
  <BaseLayout mainClass="bg-oGray">
    <section class="px-5  bg-gray-100 ">
      <div>
        <div class="grid grid-cols-12 gap-4 mt-10 dashboard-box">
          <div
            class="bg-oWhite col-span-12 md:col-span-4 shadow-md  border  px-5 py-3 rounded-xl"
          >
            <p class="text-gray-500 ">
              Your estimated earnings today
              <span
                class="tooltip-question-mark"
                v-tooltip="`Real-time estimated earnings before refunds`"
              ></span>
            </p>
            <p class="text-3xl font-medium">
              {{ getCurrency }}
              {{ dashboardData.earning_today }}
            </p>
          </div>
          <div
            class="bg-oWhite col-span-12 md:col-span-4 shadow-md  border  px-5 py-3 rounded-xl"
          >
            <p class="text-gray-500">
              Your earnings this month
              <span
                class="tooltip-question-mark"
                v-tooltip="`Your earnings this month including yesterday`"
              ></span>
            </p>
            <p class="text-3xl font-medium">
              {{ getCurrency }}
              {{ dashboardData.earning_current_month }}
            </p>
          </div>
          <div
            class="bg-oWhite col-span-12 md:col-span-4 shadow-md  border  px-5 py-3 rounded-xl"
          >
            <p class="text-gray-500">
              Available funds
              <span
                class="tooltip-question-mark"
                v-tooltip="`Funds available for payout`"
              ></span>
            </p>
            <p class="text-3xl font-medium">
              {{ getCurrency }}
              {{ dashboardData.available_funds }}
            </p>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-4 mt-10 ">
          <div
            class="bg-oWhite col-span-12 md:col-span-4 shadow-md  border  px-5 py-3 rounded-xl"
          >
            <div class="total-vehicles-bg grid grid-cols-12  ">
              <div class="col-span-5">
                <p class="tex-lg text-gray-500">YOUR VEHICLES</p>
                <p class="text-4xl font-semibold">
                  {{ dashboardData.total_assigned_vehicles }}
                </p>
              </div>
              <div class="col-span-7 flex justify-end">
                <img
                  src="@/assets/marketplace/rent-earn.png"
                  alt="vehicle"
                  class=" object-contain h-40   -mt-10 pr-2"
                />
              </div>
            </div>
            <div class="flex justify-between ">
              <p class="text-gray-500">
                Your total earnings
                <span
                  class="tooltip-question-mark"
                  v-tooltip="
                    `Your all time earnings, excluding today in your selected display currency `
                  "
                ></span>
              </p>
              <p>{{ getCurrency }} {{ dashboardData.total_earning }}</p>
            </div>
            <div class="flex justify-between ">
              <p class="text-gray-500">CO<sub>2</sub> emissions saved</p>
              <p>
                {{ dashboardData.co2saved }}
              </p>
            </div>
          </div>
          <div
            class="bg-oWhite col-span-12 md:col-span-8 shadow-md  border  px-5 py-3 rounded-xl"
          >
            <div class="grid grid-cols-12 mb-5">
              <div class="col-span-12 md:col-span-6">
                <p class="text-lg text-gray-500">YOUR EARNINGS</p>
                <div class="flex gap-2">
                  <p class="text-3xl font-medium">
                    {{ getCurrency }}
                    {{ revenueData.total_earning }}
                  </p>
                  <div
                    :class="
                      `percentage ${
                        revenueData.percentage.isPositive
                          ? 'positive'
                          : 'negative'
                      }`
                    "
                  >
                    <i
                      :class="
                        `fas ${
                          revenueData.percentage.isPositive
                            ? 'fa-arrow-up'
                            : 'fa-arrow-down'
                        }`
                      "
                    />
                    <span v-text="revenueData.percentage.value" />%
                  </div>
                </div>
              </div>
              <div class="col-span-12 md:col-span-6 flex justify-end">
                <DashboardDatePicker @apply-date="onChangeDate($event)" />
              </div>
            </div>
            <div class="py-2 px-2 bg-white rounded">
              <div class="relative mt-1">
                <ApexCharts
                  :type="`area`"
                  :height="350"
                  :series="series"
                  :options="chartOptions"
                />
                <loading
                  :active="req.busy"
                  :is-full-page="false"
                  :z-index="150"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import DashboardDatePicker from '@/components/picker/date-range/DashboardDatePicker'
import dayjs from 'dayjs'
import { useEndpoints } from '@/composables'

export default {
  name: 'MarketpalceDashboard',
  components: {
    BaseLayout,
    DashboardDatePicker,
  },

  data() {
    return {
      isLoading: false,
      req: {
        busy: false,
      },
      dateRange: {
        start: dayjs()
          .subtract(1, 'week')
          .format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      },

      dashboardData: {
        currency: {
          symbol: '--',
        },
      },
      revenueData: {
        total_earning: 0,
        compare: 0,
        percentage: {
          value: 0,
          isPositive: true,
        },
      },
      series: [],

      chartOptions: {
        chart: {
          id: 'areaChartCombo',
          type: 'area',
          height: 350,
          zoom: {
            enabled: false,
          },
        },

        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: 'straight',
        },

        labels: [],

        xaxis: {
          type: 'datetime',
        },

        yaxis: {
          opposite: true,
          forceNiceScale: true,
        },

        legend: {
          horizontalAlign: 'left',
        },

        noData: {
          text: 'Loading...',
        },

        tooltip: {
          // eslint-disable-next-line no-unused-vars
          custom: function({ series, seriesIndex, dataPointIndex, w }) {
            function formatDate(timestamp) {
              return dayjs(timestamp).format('DD MMM, YY')
            }

            // data: { y:value, x:timestamp, past: { y:value, x:timestamp } }
            const data =
              w.globals.initialSeries[seriesIndex].data[dataPointIndex]

            // console.log('tooltip', data, w)

            return `<div class="overflow-hidden w-40 text-sm">
                        <div class="flex justify-between items-center py-1 px-1 text-gray-800 border-b bg-my-gray-200">
                          <span>${formatDate(data.x)}</span>

                          <span class="font-bold">vs</span>

                          <span>${formatDate(data.past.x)}</span>
                        </div>

                        <div class="flex justify-between items-center py-2 px-2 text-sm text-gray-700">
                          <div class="flex gap-2 items-center">
                            <div class="font-bold">${data.y}</div>
                          </div>

                            <div class="flex">
                              <div class="w-2 h-2 rounded-full border border-blue-600"></div>
                            <div class="w-2 h-2 rounded-full border border-gray-600"></div>
                            </div>


                          <div class="flex gap-2 items-center">
                            <div class="font-bold">${data.past.y}</div>
                          </div>
                        </div>
                      </div>`
          },
        },
      },
    }
  },

  computed: {
    getEndpoint() {
      return useEndpoints.marketplaceBuyer.dashboardRevenue(this.dateRange)
    },
    getCurrency() {
      return this.dashboardData.currency.symbol || '--'
    },
  },

  async created() {
    localStorage.removeItem('route-path')
    await this.getRevenueData()
    await this.getDashboardData()
  },

  methods: {
    async getDashboardData() {
      this.isLoading = true
      await this.$http
        .get(useEndpoints.marketplaceBuyer.dashboard())
        .then((res) => {
          console.log('dashboardData', res.data.data)
          this.dashboardData = res.data.data
        })
        .finally(() => (this.isLoading = false))
    },
    async getRevenueData() {
      this.req.busy = true
      await this.$http
        .get(this.getEndpoint)
        .then(({ data }) => {
          const { current, past } = data
          const { max, compare, total } = current
          this.revenueData.total_earning = total
          this.revenueData.percentage = {
            value: Math.abs(compare),
            isPositive: compare >= 0,
          }

          this.updateChart({ series: this.getSeries({ current, past }), max })
        })
        .catch((e) => console.log('getRevenueDataErr', e, e.response))
        .finally(() => (this.req.busy = false))
    },

    getSeries({ current, past }) {
      const series = []
      const dateDiff = dayjs(this.dateRange.end).diff(
        this.dateRange.start,
        'days'
      )

      const currentLength = current.data.length
      if (currentLength !== dateDiff) {
        // determine the max number of data to be filled
        const maxFill = dateDiff - currentLength

        // fill in missing dates
        const theDate =
          currentLength > 0
            ? dayjs(current.date[current.data.length - 1]).add(1, 'days')
            : dayjs(this.dateRange.start)

        for (let n = 0; n < maxFill; n++) {
          const y = 0
          const pastDate = dayjs(theDate)
            .clone()
            .subtract(1, 'weeks')
            .add(n, 'days')
            .utc()
            .format()

          const currentDate = dayjs(theDate)
            .clone()
            .add(n, 'days')
            .utc()
            .format()

          past.data.push(y)
          past.date.push(pastDate)

          current.data.push(y)
          current.date.push(currentDate)
        }
      }

      current.data.forEach((d, i) => {
        series.push({
          y: d,
          x: current.date[i],
          past: {
            y: past.data[i],
            x: past.date[i],
          },
        })
      })

      return series
    },

    updateChart({ series, max }) {
      console.log('updateChart', series, max)
      // update apex chart
      this.$apexcharts.exec('areaChartCombo', 'updateOptions', {
        series: [{ data: series }],
        yaxis: {
          opposite: true,
          forceNiceScale: true,
          max: (v) => (v === max ? v + 1 : v),
        },
      })
    },
    async onChangeDate(event) {
      this.dateRange = event
      this.getRevenueData()
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-box {
  min-height: 100px;
}
.tooltip-question-mark {
  font-size: 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  color: rgba(75, 85, 99, 1);
  background-color: rgba(243, 244, 246, 1);
  border-radius: 9999px;
  border: 1px solid rgba(156, 163, 175, 1);
  cursor: default;

  &::after {
    content: '?';
  }
}
.total-vehicles-bg {
  height: 20vh;
  width: 100%;
  // border: 1px solid red;
  background-position: right;
  background-repeat: no-repeat;
  margin-bottom: 2rem;
  // object-fit: contain;
  background-size: cover;
  background-image: url('~@/assets/marketplace/box-bg.png');
}
.percentage {
  display: flex;
  align-items: center;
  column-gap: 2px;

  &.positive {
    color: #09aa35;
  }
  &.negative {
    color: #d90a20;
  }
}
</style>
