var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-layout',[_c('content-section',{staticClass:" bg-white history-box",attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-4 md:grid-cols-2"},[_c('TitlePlus',{attrs:{"title":"Purchase","hide-plus":true}})],1)]),_c('FSTable',{attrs:{"fst-id":"PurchaseHistoryIndex","searchEnabled":false,"headers":_vm.getTableHeaders,"endpoint":_vm.indexDataEndpoint},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"truncate":-5}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(item.billing ? _vm.getPayerName(item.billing) : '--')+" ")]),_c('FSTableRowItem',[_vm._v(" "+_vm._s(item.billing ? _vm.getPayerEmail(item.billing) : '--')+" ")]),_c('FSTableRowItem',{attrs:{"text":item.created_at,"date":true}}),_c('FSTableRowItem',[_c('RouterLink',{staticClass:"text-blue-600 capitalize",attrs:{"to":{
                      name: 'MarketplaceOrderDetails',
                      params: { id: item.marketplace_fleet.id },
                    }}},[_vm._v(" "+_vm._s(item.marketplace_fleet.brand_name)+" ")])],1),_c('FSTableRowItem',{attrs:{"text":item.number_of_vehicles_to_buy}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(((item.marketplace_fleet.fleet.country.currency_symbol) + " " + (item.order_amount))))]),_c('FSTableRowItem',[_c('XStatus',{attrs:{"text":item.order_status,"variant":_vm.getItemVariant(item.order_status),"profile":"payment"}})],1)],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',[_c('RouterLink',{staticClass:"text-blue-600 capitalize",attrs:{"to":{
                      name: 'MarketplaceOrderDetails',
                      params: { id: item.marketplace_fleet.id },
                    }}},[_vm._v(" "+_vm._s(item.marketplace_fleet.brand_name)+" ")])],1),_c('FSTableRowItem',{attrs:{"text":item.number_of_vehicles_to_buy}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(((item.marketplace_fleet.fleet.country.currency_symbol) + " " + (item.order_amount))))]),_c('FSTableRowItem',[_c('XStatus',{attrs:{"text":item.order_status,"variant":_vm.getItemVariant(item.order_status),"profile":"payment"}})],1)],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Invoice Id")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.$truncate(item.id, -5))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Payer Name")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.billing ? _vm.getPayerName(item.billing) : '--')+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Payer Email")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.billing ? _vm.getPayerEmail(item.billing) : '--')+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v("Transaction Time")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getFormattedDateTime( item.created_at, 'D MMM, YYYY hh:mm:ss a' ))+" ")])])])]):_vm._e()]:_vm._e()]})]}}])})],1)],1),_c('section',[_c('Footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }