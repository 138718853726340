<template lang="">
  <div>
    <base-layout>
      <div class="vehicles-box">
        <VehicleIndex
          :fst-id="`marketplace-buyer-vehicles`"
          :page-title="`Vehicles`"
          :endpoint="indexDataEndpoint"
          :tableHeaders="tableHeaders"
        />
      </div>
    </base-layout>
    <section>
      <Footer />
    </section>
  </div>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import { useEndpoints } from '@/composables'
import { VehicleIndex } from '@/composites/vehicle'
import Footer from '@/composites/marketplace-buyer/footer/Footer.vue'
export default {
  name: 'MarketplaceBuyerVehicles',
  components: {
    BaseLayout,
    VehicleIndex,
    Footer,
  },
  data() {
    return {
      indexDataEndpoint: useEndpoints.marketplaceBuyer.vehicles(),

      tableHeaders: [
        { text: 'Name', width: '10%', sort: null },
        { text: 'Battery', width: '10%', sort: 'lock__power_level' },
        { text: 'IMEI', width: '10%', sort: null },
        { text: 'QRCode', width: '10%', sort: null },
        { text: 'Last Connected', width: '13%', sort: null },
        { text: 'Last Located', width: '13%', sort: null },
        { text: 'Flags', width: '12%', sort: null },
        { text: 'Heart Beat', width: '8%', sort: null },
        { text: 'Last Location', width: '9%', sort: null },
      ],
    }
  },
  created() {
    localStorage.removeItem('route-path')
  },
}
</script>
<style lang="scss">
.vehicles-box {
  min-height: 100vh;
}


</style>
