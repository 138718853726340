<template>
  <div
    class="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-4 xl:col-span-4 2xl:col-span-4   hover:shadow-lg duration-200 ease-linear border border-gray-300 px-5 py-3 rounded-lg"
  >
    <div class="flex justify-between">
      <button
        class=" text-oWhite rounded-full px-3 py-1"
        :class="getItemVariant(rawdata.order_status)"
      >
        {{ getItemStatusTitle(rawdata.order_status) }}
      </button>
      <img
        v-tooltip="rawdata.fleet.country.name"
        class="w-8 h-8 rounded-full border-4 border-gray-300 ease-in duration-300 "
        :src="getCountryFlag(rawdata.fleet.country.code2)"
        alt="country-flag"
      />
    </div>
    <div class="flex justify-center  ">
      <img
        :src="rawdata.vehicle_model.vehicle_image"
        alt="bike"
        class="card-main-img py-2 "
      />
    </div>
    <div class="flex items-center justify-between py-2">
      <h4 class="text-xl font-bold">
        {{ rawdata.brand_name }}
      </h4>
      <img class="brand-image " :src="rawdata.brand_image" alt="product-logo" />
    </div>
    <div class="flex text-lg">
      <div class="w-5/12 lg:w-1/3 text-gray-700">Order amount</div>
      <div class="w-7/12 lg:w-2/3 text-gray-900">
        {{ rawdata.total_vehicle_number }} Order
      </div>
    </div>
    <div class="flex text-lg">
      <div class="w-5/12 lg:w-1/3 text-gray-700">Order Value</div>
      <div class="w-7/12 lg:w-2/3 text-gray-900">
        {{ getCurrencySymbol }} {{ rawdata.total_order_value }}
      </div>
    </div>
    <div class="flex text-lg">
      <div class="w-5/12 lg:w-1/3 text-gray-700">Vehicle Price</div>
      <div class="w-7/12 lg:w-2/3 text-gray-900">
        {{ getCurrencySymbol }} {{ rawdata.per_unit_price }}
      </div>
    </div>
    <div class="flex text-lg">
      <div class="w-5/12 lg:w-1/3 text-gray-700">Rent Share</div>
      <div class="w-7/12 lg:w-2/3 text-gray-900">
        <p>{{ rawdata.rent_share_1st_year_by_percentage }}% (1st year)</p>
        <p>{{ rawdata.rent_share_2nd_year_by_percentage }}% (2nd year)</p>
        <p>{{ rawdata.rent_share_3rd_year_by_percentage }}% (3rd year)</p>
        <p>{{ rawdata.rent_share_4th_year_by_percentage }}% (4th year)</p>
      </div>
    </div>

    <div class="py-5">
      <span class="text-lg font-bold text-gray-900"
        >{{ rawdata.total_purchased_vehicle }}/{{
          rawdata.total_vehicle_number
        }}</span
      >
      <div style="background: #D1D5DB;" class="rounded-full">
        <progress-bar-x
          :value="
            getPercentageValue(
              rawdata.total_purchased_vehicle,
              rawdata.total_vehicle_number
            )
          "
          :animated="true"
          :height="15"
          :radius="50"
          color="green"
        />
      </div>
    </div>
    <div>
      <div
        class="px-3 py-2 hover:shadow-lg duration-200 ease-linear border border-gray-300 rounded-lg "
      >
        <div
          v-if="rawdata.order_status === 'Started'"
          class="border-l-4 border-green-600 pl-4"
        >
          <p>
            Order expires on
            {{ getFormattedDateTime(rawdata.order_end_date, 'D MMM, YYYY') }}
          </p>
          <p>
            Business Starts on
            {{
              getFormattedDateTime(rawdata.operation_start_date, 'D MMM, YYYY')
            }}
          </p>
        </div>
        <div
          v-if="rawdata.order_status === 'Expired'"
          class="border-l-4 border-red-600 pl-4"
        >
          <p>
            Order Expired on
            {{ getFormattedDateTime(rawdata.order_end_date, 'D MMM, YYYY') }}
          </p>
          <p>
            Business Starts on
            {{
              getFormattedDateTime(rawdata.operation_start_date, 'D MMM, YYYY')
            }}
          </p>
        </div>
        <div
          v-if="rawdata.order_status === 'Completed'"
          class="border-l-4 border-gray-600 pl-4"
        >
          <p>Order Completed</p>
          <p>
            Business Starts on
            {{
              getFormattedDateTime(rawdata.operation_start_date, 'D MMM, YYYY')
            }}
          </p>
        </div>
        <div
          v-if="rawdata.order_status === 'Pending'"
          class="border-l-4 border-yellow-600 pl-4"
        >
          <p>
            Order Starts on
            {{ getFormattedDateTime(rawdata.order_start_date, 'D MMM, YYYY') }}
          </p>
          <p>
            Business Starst on
            {{
              getFormattedDateTime(rawdata.operation_start_date, 'D MMM, YYYY')
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="py-5 mt-10">
      <RouterLink
        :to="{
          name: 'MarketplaceOrderDetails',
          params: { id: rawdata.id },
        }"
      >
        <button
          class="w-full  py-3 rounded-full text-lg text-ed1b24 hover:bg-ed1b24 hover:text-white font-bold"
        >
          View Details
        </button>
      </RouterLink>
    </div>
  </div>
</template>
<script>
import ProgressBarX from '@/components/analytics/charts/ProgressBarX'
import { findFlagUrlByIso2Code } from 'country-flags-svg'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'PreorderCard',
  components: {
    ProgressBarX,
  },
  props: {
    rawdata: {
      type: Object,
    },
  },
  computed: {
    getCurrencySymbol() {
      return this.rawdata.fleet.country.currency_symbol
    },
  },
  methods: {
    getFormattedDateTime,
    findFlagUrlByIso2Code,
    getItemVariant(data) {
      if (data === 'Completed') return 'bg-oGray'
      if (data === 'Expired') return 'bg-oRed'
      if (data === 'Started') return 'bg-oGreen'
      return 'bg-oOrange'
    },
    getItemStatusTitle(data) {
      if (data === 'Started') return 'In Progress'
      if (data === 'Pending') return 'Upcoming'
      return data
    },
    getPercentageValue(purchasedVehicle, totalVehicle) {
      const f = purchasedVehicle / totalVehicle
      return f * 100
    },
    getCountryFlag(iso2Code) {
      return this.findFlagUrlByIso2Code(iso2Code)
    },
  },
}
</script>
<style lang="scss">
.brand-image {
  max-height: 28px;
  max-width: 90px;
}
.card-main-img {
  width: 100%;
  max-width: 150px;
  max-height: 160px;
}

button:hover {
  background-color: var(--brand-primary-color);
}
.text-ed1b24 {
  color: var(--brand-primary-color);
  border: 2px solid var(--brand-primary-color);
}
</style>
