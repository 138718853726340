<template lang="">
  <div>
    <base-layout>
      <content-section :spacing="false" class=" bg-white history-box">
        <div class="content-spacing">
          <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
            <TitlePlus title="Purchase" :hide-plus="true" />
          </div>
        </div>
        <!-- <div class="flex flex-col gap-2 my-1 md:items-center md:flex-row pl-5">
        <SummaryCard
          title="Total"
          :value="indexMetaData.count.total"
          variant="purple"
        />
      </div> -->

        <FSTable
          :fst-id="`PurchaseHistoryIndex`"
          :searchEnabled="false"
          :headers="getTableHeaders"
          :endpoint="indexDataEndpoint"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex" text-fallback-always>
                  <FSTableRowItem :text="item.id" :truncate="-5" />
                  <FSTableRowItem>
                    {{ item.billing ? getPayerName(item.billing) : '--' }}
                  </FSTableRowItem>
                  <FSTableRowItem>
                    {{ item.billing ? getPayerEmail(item.billing) : '--' }}
                  </FSTableRowItem>
                  <FSTableRowItem :text="item.created_at" :date="true" />
                  <FSTableRowItem>
                    <RouterLink
                      :to="{
                        name: 'MarketplaceOrderDetails',
                        params: { id: item.marketplace_fleet.id },
                      }"
                      class="text-blue-600 capitalize"
                    >
                      {{ item.marketplace_fleet.brand_name }}
                    </RouterLink>
                  </FSTableRowItem>
                  <FSTableRowItem :text="item.number_of_vehicles_to_buy" />
                  <FSTableRowItem>
                    {{
                      `${item.marketplace_fleet.fleet.country.currency_symbol} ${item.order_amount}`
                    }}</FSTableRowItem
                  >
                  <FSTableRowItem>
                    <XStatus
                      :text="item.order_status"
                      :variant="getItemVariant(item.order_status)"
                      profile="payment"
                    />
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <RouterLink
                      :to="{
                        name: 'MarketplaceOrderDetails',
                        params: { id: item.marketplace_fleet.id },
                      }"
                      class="text-blue-600 capitalize"
                    >
                      {{ item.marketplace_fleet.brand_name }}
                    </RouterLink>
                  </FSTableRowItem>
                  <FSTableRowItem :text="item.number_of_vehicles_to_buy" />
                  <FSTableRowItem>
                    {{
                      `${item.marketplace_fleet.fleet.country.currency_symbol} ${item.order_amount}`
                    }}</FSTableRowItem
                  >
                  <FSTableRowItem>
                    <XStatus
                      :text="item.order_status"
                      :variant="getItemVariant(item.order_status)"
                      profile="payment"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Invoice Id</div>
                      <div class="col-span-5 right-text">
                        {{ $truncate(item.id, -5) }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Payer Name</div>
                      <div class="col-span-5 right-text">
                        {{ item.billing ? getPayerName(item.billing) : '--' }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Payer Email</div>
                      <div class="col-span-5 right-text">
                        {{ item.billing ? getPayerEmail(item.billing) : '--' }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Transaction Time</div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.created_at,
                            'D MMM, YYYY hh:mm:ss a'
                          )
                        }}
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </content-section>
    </base-layout>
    <section>
      <Footer />
    </section>
  </div>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import XStatus from '@/components/badge/XStatus'
import { useEndpoints } from '@/composables'
import { getFormattedDateTime } from '@/utils/datetime'
import Footer from '@/composites/marketplace-buyer/footer/Footer.vue'
export default {
  name: 'PurchaseHistory',
  components: {
    // SummaryCard: () => import('@/components/cards/SummaryCard'),
    BaseLayout,
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    Footer,
  },
  data() {
    return {
      indexDataEndpoint: useEndpoints.marketplaceBuyer.purchaseHistoryIndex(),
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      path: '',
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        { text: 'Invoice Id', width: '5%', sort: 'id' },
        { text: 'Payer Name', width: '10%', sort: 'null' },
        { text: 'Payer Email', width: '10%', sort: 'null' },
        { text: 'Transaction Time', width: '10%', sort: 'null' },
        { text: 'Brand Name', width: '10%', sort: 'null' },
        { text: 'No of Vehicle', width: '7%', sort: 'null' },
        { text: 'Amount', width: '10%', sort: 'null' },
        // { text: 'Payment Method', width: '10%', sort: 'null' },
        { text: 'Status', width: '10%', sort: 'null' },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'Brand Name', width: '35%', sort: null },
        { text: 'Vehicle', width: '20%', sort: null },
        { text: 'Amount', width: '15%', sort: null },
        { text: 'Status', width: '25%', sort: 'null' },
      ],
    }
  },
  created() {
    localStorage.removeItem('route-path')
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getItemVariant(data) {
      if (data === 'paid') return 'green'
      if (data === 'failed') return 'red'
      if (data === 'unpaid') return 'gray'
      return 'orange'
    },
    getPayerName(data) {
      if (data.billing_category === 'individual') {
        return data.legal_name
      } else {
        return data.company_name
      }
    },
    getPayerEmail(data) {
      if (data.billing_category === 'individual') {
        return data.email
      } else {
        return data.company_email
      }
    },
  },
}
</script>
<style lang="scss">
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
.history-box {
  min-height: 100vh;
}
</style>
