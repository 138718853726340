<template lang="">
  <div>
    <base-layout>
      <section class="px-5 md:px-16 lg:px-32 xl:px-40 2xl:px-40 bg-gray-100">
        <div>
          <h2 class="text-2xl md:text-3xl font-bold">All Orders</h2>
        </div>
        <div class="relative  py-5 order-box">
          <div class="grid grid-cols-12 gap-4 mt-10 ">
            <loading
              :active.sync="isLoading"
              :is-full-page="false"
              class="bg-gray-200"
            />
            <template v-for="(item, index) in preordersData">
              <PreorderCard :key="`preorderCard-${index}`" :rawdata="item" class="bg-white" />
            </template>
          </div>
        </div>
      </section>
    </base-layout>
    <section>
      <Footer />
    </section>
  </div>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import PreorderCard from '@/composites/marketplace-buyer/preorder-card/PreorderCard.vue'
import { useEndpoints } from '@/composables'
import Footer from '@/composites/marketplace-buyer/footer/Footer.vue'
export default {
  name: 'MarketplaceAllPrimaryOrders',
  components: {
    BaseLayout,
    PreorderCard,
    Footer,
  },
  data() {
    return {
      isLoading: false,
      preordersData: [],
    }
  },
  created() {
    localStorage.removeItem('route-path')
    this.getPreorderList()
  },
  methods: {
    async getPreorderList() {
      this.isLoading = true
      await this.$http
        .get(useEndpoints.marketplaceBuyer.preorderIndex())
        .then((res) => {
          this.preordersData = res.data.data
          console.log('preordersData', this.preordersData)
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
<style lang="scss">
.order-box {
  min-height: 100vh;
}
</style>
